import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import LoadingScreen from './screens/LoadingScreen/LoadingScreen';
import Menu from './components/Menu/Menu';
import MainScreen from './screens/MainScreen/MainScreen';
import WorksScreen2 from './screens/WorksScreen2/WorksScreen2';
import WorksScreen from './screens/WorksScreen/WorksScreen';
import './styles/global.scss';
import ContactScreen from './screens/ContactScreen/ContactScreen';

const App: React.FC = () => {
  const { isLoading } = useSelector((state: RootState) => state.ui);
  const [hasLoaded, setHasLoaded] = useState(false);
  
  // 컴포넌트가 처음 마운트될 때 실행
  useEffect(() => {
    // 이미 로딩된 적이 있으면 hasLoaded를 true로 설정
    const hasVisitedBefore = sessionStorage.getItem('hasLoaded');
    if (hasVisitedBefore) {
      setHasLoaded(true);
    }
  }, []);
  
  // 로딩이 완료되면 세션 스토리지에 기록
  useEffect(() => {
    if (!isLoading && !hasLoaded) {
      sessionStorage.setItem('hasLoaded', 'true');
      setHasLoaded(true);
    }
  }, [isLoading, hasLoaded]);

  // 새로고침 시에는 hasLoaded가 true이므로 로딩 화면을 건너뜁니다
  const shouldShowLoading = isLoading && !hasLoaded;

  return (
    <Router>
      <div className="app">
        <Menu />
        {shouldShowLoading ? (
          <LoadingScreen duration={3000} />
        ) : (
          <main className="main-content">
            <Routes>
              <Route path="/" element={<MainScreen />} />
              <Route path="/works" element={<WorksScreen />} />
              <Route path="/contact" element={<ContactScreen />} />
              <Route path="/works/:workId" element={<WorksScreen2 />} />
              <Route path="*" element={<div className="container">페이지가 준비 중입니다.</div>} />
            </Routes>
          </main>
        )}
      </div>
    </Router>
  );
};

export default App;