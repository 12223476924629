import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { toggleTheme } from '../../redux/slices/themeSlice';
import { RootState } from '../../redux/store';
import styles from './Menu.module.scss';
import logoImage from '../../assets/minimize-logo.png';

const Menu: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { mode } = useSelector((state: RootState) => state.theme);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // 스크롤 이벤트 감지
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // 현재 경로에 따른 활성 메뉴 아이템 확인
  const isActive = (path: string) => {
    return location.pathname === path;
  };

  // 모바일 메뉴 토글
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };

  return (
    <header 
      className={`${styles.header} ${isScrolled ? styles.scrolled : ''} ${mode === 'light' ? styles.light : styles.dark}`}
    >
      <div className={styles.container}>
        {/* 로고 */}
        <div className={styles.logo}>
          <Link to="/">
            <img src={logoImage} alt="Minimize 로고" className={styles.logoImage} />
            {/* <span className={styles.logoText}>MINIMIZE</span> */}
          </Link>
        </div>

        {/* 데스크탑 메뉴 */}
        <nav className={styles.desktopMenu}>
          <ul className={styles.navItems}>
            <li className={isActive('/works') ? styles.active : ''}>
              <Link to="/works">Works</Link>
            </li>
            <li className={isActive('/contact') ? styles.active : ''}>
              <Link to="/contact">Contact</Link>
            </li>
            {/* <li>
              <button 
                className={styles.themeToggle} 
                onClick={handleThemeToggle}
                aria-label={`Switch to ${mode === 'light' ? 'dark' : 'light'} mode`}
              >
                {mode === 'light' ? '🌙' : '☀️'}
              </button>
            </li> */}
          </ul>
        </nav>

        {/* 모바일 메뉴 버튼 */}
        <button 
          className={`${styles.mobileMenuButton} ${isMobileMenuOpen ? styles.open : ''}`}
          onClick={toggleMobileMenu}
          aria-label="메뉴 토글"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        {/* 모바일 메뉴 */}
        {/* <div className={`${styles.mobileMenu} ${isMobileMenuOpen ? styles.open : ''} ${mode === 'light' ? styles.light : styles.dark}`}>
          <nav>
            <ul className={styles.mobileNavItems}>
              <li className={isActive('/works') ? styles.active : ''}>
                <Link to="/works" onClick={() => setIsMobileMenuOpen(false)}>Works</Link>
              </li>
              <li className={isActive('/contact') ? styles.active : ''}>
                <Link to="/contact" onClick={() => setIsMobileMenuOpen(false)}>Contact</Link>
              </li>
              <li>
                <button 
                  className={styles.themeToggle} 
                  onClick={handleThemeToggle}
                  aria-label={`${mode === 'light' ? '다크' : '라이트'} 모드로 전환`}
                >
                  {mode === 'light' ? '🌙 다크 모드' : '☀️ 라이트 모드'}
                </button>
              </li>
            </ul>
          </nav>
        </div> */}
      </div>
    </header>
  );
};

export default Menu;