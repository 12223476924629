import React, { useEffect, useRef, useState } from 'react';
import styles from './Section5.module.scss';

interface TextItem {
  id: number;
  text: string;
}

const Section5: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const rightTextRef = useRef<HTMLDivElement>(null);
  
  // 현재 보이는 텍스트의 인덱스
  const [activeTextIndex, setActiveTextIndex] = useState<number>(0);
  // 컨테이너의 상태 관리 (상단, 고정, 하단)
  const [containerState, setContainerState] = useState<'top' | 'fixed' | 'bottom'>('top');
  
  // 텍스트 항목 데이터
  const textItems: TextItem[] = [
    { id: 1, text: "복잡한 업무를" },
    { id: 2, text: "단순반복 업무를" },
    { id: 3, text: "당신의 스트레스를" },
    { id: 4, text: "불필요함을" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (!sectionRef.current) return;
      
      // 페이지 스크롤 위치 가져오기
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      
      // 섹션의 시작과 끝 위치 계산
      const sectionTop = sectionRef.current.offsetTop;
      const sectionHeight = sectionRef.current.offsetHeight;
      const sectionBottom = sectionTop + sectionHeight;
      
      // fixed 영역 시작/끝 지점 설정
      const fixedStartPoint = sectionTop - windowHeight * 0.2; // 섹션 시작 전 20% 지점부터 fixed
      const fixedEndPoint = sectionTop + sectionHeight * 0.6; // 섹션 시작 후 60% 지점까지 fixed
      
      // 컨테이너 상태 업데이트
      if (scrollTop < fixedStartPoint) {
        // 아직 fixed 영역에 도달하지 않음 (상단 영역)
        setContainerState('top');
      } else if (scrollTop >= fixedStartPoint && scrollTop < fixedEndPoint) {
        // fixed 영역 내부
        setContainerState('fixed');
        
        // 스크롤 진행률에 따라 활성 텍스트 인덱스 계산
        const progressInFixedArea = (scrollTop - fixedStartPoint) / (fixedEndPoint - fixedStartPoint);
        const textIndex = Math.min(
          Math.floor(progressInFixedArea * textItems.length),
          textItems.length - 1
        );
        setActiveTextIndex(textIndex);
      } else {
        // fixed 영역을 지나침 (하단 영역)
        setContainerState('bottom');
        setActiveTextIndex(textItems.length - 1); // 마지막 텍스트 보이게 유지
      }
    };

    // 스크롤 이벤트 리스너 등록 (throttle 적용하여 성능 최적화)
    let ticking = false;
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollListener);
    // 초기 실행
    handleScroll();
    
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [textItems.length]);

  return (
    <section ref={sectionRef} className={styles.section5}>
      <div 
        ref={containerRef} 
        className={`
          ${styles.container} 
          ${containerState === 'fixed' ? styles.fixed : ''} 
          ${containerState === 'bottom' ? styles.bottom : ''}
        `}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.leftContent}>
            {textItems.map((item, index) => (
              <div 
                key={item.id}
                className={`
                  ${styles.leftText} 
                  ${activeTextIndex === index ? styles.active : ''}
                `}
              >
                {item.text}
              </div>
            ))}
          </div>
          
          <div ref={rightTextRef} className={styles.rightContent}>
            <div className={styles.rightText}>최소화</div>
          </div>
        </div>
      </div>
      
      {/* 고정 컨테이너를 위한 공간 확보 (fixed 상태일 때 일반 흐름에서 빠지므로) */}
      <div className={styles.spacer} style={{ 
        height: containerState === 'fixed' ? '100vh' : '0' 
      }} />
    </section>
  );
};

export default Section5;