import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/slices/uiSlice';
import styles from './LoadingScreen.module.scss';

interface LoadingScreenProps {
  duration?: number; // 로딩 화면 지속 시간 (밀리초)
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ duration = 5000 }) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [phase, setPhase] = useState<'initial' | 'shrinking' | 'transforming' | 'final' | 'complete'>('initial');
  const requestRef = useRef<number | undefined>(undefined);
  const startTimeRef = useRef<number | undefined>(undefined);
  const initialPhaseCompleteRef = useRef<boolean>(false);
  const shrinkingPhaseCompleteRef = useRef<boolean>(false);
  const transformingPhaseCompleteRef = useRef<boolean>(false);

  // 애니메이션 프레임 요청 함수
  const animate = (time: number) => {
    if (startTimeRef.current === undefined) {
      startTimeRef.current = time;
    }

    const elapsed = time - startTimeRef.current;
    // 전체 로딩 시간에 따른 진행률 계산 (0-100%)
    const newProgress = Math.min(100, (elapsed / duration) * 100);
    setProgress(newProgress);

    // 초기 단계 (0-30% 시간 동안) - 흰색 배경이 화면을 꽉 채움
    if (newProgress <= 50 && !shrinkingPhaseCompleteRef.current) {
      setPhase('shrinking');
      initialPhaseCompleteRef.current = true;
    } 
    // 변형 단계 (50-70% 시간 동안) - 흰색 배경이 로고 모양으로 변형
    else if (newProgress <= 70 && !transformingPhaseCompleteRef.current) {
      setPhase('transforming');
      shrinkingPhaseCompleteRef.current = true;
    }
    // 최종 단계 (70-99% 시간 동안) - 로고가 중앙에 배치되고 크기 유지
    else if (newProgress < 99) {
      setPhase('final');
      transformingPhaseCompleteRef.current = true;
    } 
    // 애니메이션 완료
    else {
      setPhase('complete');
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 500); 
      return;
    }

    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    dispatch(setLoading(true));
    requestRef.current = requestAnimationFrame(animate);
    
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [dispatch]);

  // Progress bar 애니메이션 효과
  const getWaveProgress = () => {
    // 출렁이는 효과를 주기 위해 사인파 함수 사용
    const wave = Math.sin(Date.now() / 200) * 3;
    return Math.min(100, progress + wave);
  };

  return (
    <div className={styles.loadingScreen}>
      <div className={`${styles.container} ${styles[phase]}`}>
        {/* 흰색 배경 */}
        <div className={styles.whiteBackground}></div>
        
        {/* 로고 (흰색) */}
        <div className={styles.logoContainer}>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 65.89 65.34" 
            className={styles.logoSvg}
          >
            <rect 
              x="3" 
              y="3" 
              width="60" 
              height="60" 
              rx="8" 
              fill="white" 
              stroke="none"
            />
            <line 
              stroke="black" 
              strokeWidth="6" 
              strokeLinecap="round" 
              x1="15" 
              y1="50" 
              x2="50" 
              y2="50" 
            />
          </svg>
        </div>
      </div>

      {/* 로딩 진행 표시줄 */}
      <div className={styles.progressContainer}>
        <div 
          className={styles.progressBar} 
          style={{ width: `${getWaveProgress()}%` }}
        ></div>
        <div className={styles.progressText}>{Math.floor(progress)}%</div>
      </div>
    </div>
  );
};

export default LoadingScreen;