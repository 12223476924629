import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './Footer.module.scss';

// 모달 컴포넌트 타입 정의
interface ModalProps {
  title: string;
  content: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

// 모달 컴포넌트
const Modal: React.FC<ModalProps> = ({ title, content, onClose, isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h2>{title}</h2>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>
        <div className={styles.modalBody}>
          {content}
        </div>
      </div>
    </div>
  );
};

const Footer: React.FC = () => {
  const { mode } = useSelector((state: RootState) => state.theme);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  // 이용약관 내용
  const termsContent = (
    <div className={styles.legalContent}>
      <section>
        <h3>제1조 목적</h3>
        <p>
          본 이용약관은 "비딩윈"(이하 "사이트")의 서비스의 이용조건과 운영에 관한 제반 사항 규정을 목적으로 합니다.
        </p>
      </section>
      
      <section>
        <h3>제2조 용어의 정의</h3>
        <p>
          본 약관에서 사용되는 주요한 용어의 정의는 다음과 같습니다.
        </p>
        <p>
          ① 회원 : 사이트의 약관에 동의하고 개인정보를 제공하여 회원등록을 한 자로서, 사이트와의 이용계약을 체결하고 사이트를 이용하는 이용자를 말합니다.<br />
          ② 이용계약 : 사이트 이용과 관련하여 사이트와 회원간에 체결 하는 계약을 말합니다.<br />
          ③ 회원 아이디(이하 "ID") : 회원의 식별과 회원의 서비스 이용을 위하여 회원별로 부여하는 고유한 문자와 숫자의 조합을 말합니다.<br />
          ④ 비밀번호 : 회원이 부여받은 ID와 일치된 회원임을 확인하고 회원의 권익 보호를 위하여 회원이 선정한 문자와 숫자의 조합을 말합니다.<br />
          ⑤ 운영자 : 서비스에 홈페이지를 개설하여 운영하는 운영자를 말합니다.<br />
          ⑥ 해지 : 회원이 이용계약을 해약하는 것을 말합니다.
        </p>
      </section>
      
      <section>
        <h3>제3조 약관 외 준칙</h3>
        <p>
          운영자는 필요한 경우 별도로 운영정책을 공지 안내할 수 있으며, 본 약관과 운영정책이 중첩될 경우 운영정책이 우선 적용됩니다.
        </p>
      </section>
      
      <section>
        <h3>제4조 이용계약 체결</h3>
        <p>
          ① 이용계약은 회원으로 등록하여 사이트를 이용하려는 자의 본 약관 내용에 대한 동의와 가입신청에 대하여 운영자의 이용승낙으로 성립합니다.<br />
          ② 회원으로 등록하여 서비스를 이용하려는 자는 사이트 가입신청 시 본 약관을 읽고 아래에 있는 "동의합니다"를 선택하는 것으로 본 약관에 대한 동의 의사 표시를 합니다.
        </p>
      </section>

      <section>
        <h3>제5조 서비스 이용 신청</h3>
        <p>
          ① 회원으로 등록하여 사이트를 이용하려는 이용자는 사이트에서 요청하는 제반정보(이용자ID,비밀번호, 닉네임 등)를 제공해야 합니다.<br />
          ② 타인의 정보를 도용하거나 허위의 정보를 등록하는 등 본인의 진정한 정보를 등록하지 않은 회원은 사이트 이용과 관련하여 아무런 권리를 주장할 수 없으며, 관계 법령에 따라 처벌받을 수 있습니다.
        </p>
      </section>
      
      <section>
        <h3>제6조 개인정보처리방침</h3>
        <p>
          사이트 및 운영자는 회원가입 시 제공한 개인정보 중 비밀번호를 가지고 있지 않으며 이와 관련된 부분은 사이트의 개인정보처리방침을 따릅니다.<br />
          운영자는 관계 법령이 정하는 바에 따라 회원등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다.
        </p>
        <p>
          회원의 개인정보보호에 관하여 관계법령 및 사이트가 정하는 개인정보처리방침에 정한 바에 따릅니다.
        </p>
        <p>
          단, 회원의 귀책 사유로 인해 노출된 정보에 대해 운영자는 일체의 책임을 지지 않습니다.<br />
          운영자는 회원이 미풍양속에 저해되거나 국가안보에 위배되는 게시물 등 위법한 게시물을 등록 · 배포할 경우 관련 기관의 요청이 있을 시 회원의 자료를 열람 및 해당 자료를 관련 기관에 제출할 수 있습니다.
        </p>
      </section>
      
      <section>
        <h3>제7조 운영자의 의무</h3>
        <p>
          ① 운영자는 이용회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 가급적 빨리 처리하여야 합니다. 다만, 개인적인 사정으로 신속한 처리가 곤란한 경우에는 사후에 공지 또는 이용회원에게 쪽지, 전자우편 등을 보내는 등 최선을 다합니다.<br />
          ② 운영자는 계속적이고 안정적인 사이트 제공을 위하여 설비에 장애가 생기거나 유실된 때에는 이를 지체 없이 수리 또는 복구할 수 있도록 사이트에 요구할 수 있습니다. 다만, 천재지변 또는 사이트나 운영자에 부득이한 사유가 있는 경우, 사이트 운영을 일시 정지할 수 있습니다.
        </p>
      </section>
      
      <p className={styles.lastUpdated}>
        이 약관은 &lt;2021.09.15&gt;부터 시행합니다.
      </p>
    </div>
  );

  // 개인정보처리방침 내용
  const privacyContent = (
    <div className={styles.legalContent}>
      <p className={styles.introduction}>
        비딩윈(플랫플랫)(이하 '회사'라 한다)는 개인정보 보호법 제30조에 따라 정보 주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립, 공개합니다.
      </p>
      
      <section>
        <h3>제1조 (개인정보의 처리목적)</h3>
        <p>
          회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </p>
        <p>
          1. 홈페이지 회원 가입 및 관리<br />
          회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증, 회원자격 유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정 이용 방지, 만 14세 미만 아동의 개인정보처리 시 법정대리인의 동의 여부 확인, 각종 고지․통지, 고충 처리 등을 목적으로 개인정보를 처리합니다.
        </p>
        <p>
          2. 재화 또는 서비스 제공<br />
          물품 배송, 서비스 제공, 계약서 및 청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금 결제 및 정산, 채권추심 등을 목적으로 개인정보를 처리합니다.
        </p>
        <p>
          3. 고충 처리<br />
          민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지, 처리 결과 통보 등의 목적으로 개인정보를 처리합니다.
        </p>
      </section>
      
      <section>
        <h3>제2조 (개인정보의 처리 및 보유기간)</h3>
        <p>
          ① 회사는 법령에 따른 개인정보 보유, 이용 기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용 기간 내에서 개인정보를 처리, 보유합니다.<br />
          ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
        </p>
        <p>
          1. 홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴 시까지<br />
          다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지<br />
          1) 관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우에는 해당 수사, 조사 종료 시까지<br />
          2) 홈페이지 이용에 따른 채권 및 채무관계 잔존 시에는 해당 채권, 채무 관계 정산 시까지
        </p>
      </section>
      
      <section>
        <h3>제5조(이용자 및 법정대리인의 권리와 그 행사 방법)</h3>
        <p>
          ① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.<br />
          1. 개인정보 열람 요구<br />
          2. 오류 등이 있을 경우 정정 요구<br />
          3. 삭제요구<br />
          4. 처리정지 요구<br />
          ② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.<br />
          ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br />
          ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br />
          ⑤ 정보주체는 개인정보 보호법 등 관계 법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니 됩니다.
        </p>
      </section>
      
      <section>
        <h3>제6조(처리하는 개인정보 항목)</h3>
        <p>
          회사는 다음의 개인정보 항목을 처리하고 있습니다.
        </p>
        <p>
          1. 홈페이지 회원 가입 및 관리<br />
          필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 성별, 이메일주소<br />
          선택항목 : 관심 분야, 거래 정보
        </p>
        <p>
          2. 재화 또는 서비스 제공<br />
          필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 성별, 이메일주소, API정보, 광고매체 계정정보<br />
          선택항목 : 관심 분야, 거래 정보
        </p>
        <p>
          3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.<br />
          IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등
        </p>
      </section>
      
      <section>
        <h3>제7조(개인정보의 파기)</h3>
        <p>
          ① 회사는 개인정보 보유 기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br />
          ② 정보주체로부터 동의받은 개인정보 보유 기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.<br />
          ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
        </p>
        <p>
          1. 파기 절차<br />
          회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
        </p>
        <p>
          2. 파기 방법<br />
          회사는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
        </p>
      </section>
      
      <section>
        <h3>제10조(개인정보 보호책임자)</h3>
        <p>
          ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만 처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        </p>
        <p>
          ▶ 개인정보 보호책임자<br />
          성명 : 방재희<br />
          직책 : 대표<br />
          연락처 : biddingwin@naver.com<br />
          ※ 개인정보 보호 담당부서로 연결됩니다.
        </p>
        <p>
          ▶ 개인정보 보호 담당부서<br />
          부서명 : 개발팀<br />
          담당자 : 방재희<br />
          연락처 : biddingwin@naver.com
        </p>
        <p>
          ② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만 처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
        </p>
      </section>
      
      <p className={styles.lastUpdated}>
        이 개인정보 처리방침은 2021. 09. 15부터 적용됩니다.
      </p>
    </div>
  );

  return (
    <footer className={`${styles.footer} ${styles[mode]}`}>
      <div className={styles.container}>
        <div className={styles.infoSection}>
          <div className={styles.companyInfo}>
            <p>회사명 : 미니마이즈</p>
            <p>대표자 : 방재희 | 사업자등록번호 : 623-38-00790</p>
            <p>대표 번호 : 010-2649-3689</p>
            <p>주소 : 서울시 동작구 상도로 55길 8-3, B동 3층 303호</p>
            <p>문의 : biddingwin@naver.com</p>
            <p className={styles.operatingHours}>
              (상담 운영시간 평일 월-금 09:00~18:00 점심시간 12:00~13:00)
            </p>
          </div>

          <div className={styles.legalLinks}>
            <button 
              className={styles.termsLink} 
              onClick={() => setIsTermsModalOpen(true)}
            >
              이용약관
            </button>
            <button 
              className={styles.privacyLink} 
              onClick={() => setIsPrivacyModalOpen(true)}
            >
              개인정보처리방침
            </button>
          </div>

          <div className={styles.copyright}>
            <p>Copyright ⓒ 2025 미니마이즈 All rights reserved.</p>
          </div>
        </div>
      </div>
      
      {/* 이용약관 모달 */}
      <Modal 
        title="이용약관" 
        content={termsContent} 
        onClose={() => setIsTermsModalOpen(false)} 
        isOpen={isTermsModalOpen} 
      />
      
      {/* 개인정보처리방침 모달 */}
      <Modal 
        title="개인정보처리방침" 
        content={privacyContent} 
        onClose={() => setIsPrivacyModalOpen(false)} 
        isOpen={isPrivacyModalOpen} 
      />
    </footer>
  );
};

export default Footer;