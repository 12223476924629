import React, { useEffect, useRef, useState } from 'react';
import { whatWeDoData, SlideContent } from '../../data/whatWeDoData';
import styles from './Section3.module.scss';

const Section3: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const rightPanelRef = useRef<HTMLDivElement>(null);
  const leftPanelRef = useRef<HTMLDivElement>(null);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [prevActiveSlide, setPrevActiveSlide] = useState<number>(0);
  const [isChanging, setIsChanging] = useState<boolean>(false);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);

  // data 파일에서 가져온 슬라이드 데이터 사용
  const slides: SlideContent[] = whatWeDoData;

  // 스크롤을 감지하여 어떤 슬라이드를 보여줄지 결정하는 함수
  useEffect(() => {
    // 섹션의 높이를 설정 - 슬라이드 수에 따라 조정
    const updateSectionHeight = () => {
      if (sectionRef.current) {
        // 모바일에서는 각 슬라이드마다 더 많은 공간 필요
        const isMobile = window.innerWidth <= 1024;
        const totalHeight = window.innerHeight * slides.length * (isMobile ? 1.2 : 1); // 모바일에서는 20% 더 높게
        sectionRef.current.style.height = `${totalHeight}px`;
      }
    };
    
    updateSectionHeight();
    window.addEventListener('resize', updateSectionHeight);

    const handleScroll = () => {
      if (!sectionRef.current) return;
      
      // 섹션에 상대적인 스크롤 위치 계산
      const sectionTop = sectionRef.current.offsetTop;
      const sectionEnd = sectionTop + sectionRef.current.offsetHeight - window.innerHeight;
      
      // 컨테이너 상태 설정 - 고정 또는 일반 흐름
      if (containerRef.current) {
        if (window.pageYOffset >= sectionTop && window.pageYOffset <= sectionEnd) {
          // 섹션 내부에 있을 때 - 컨테이너를 화면에 고정
          containerRef.current.classList.add(styles.fixed);
          containerRef.current.classList.remove(styles.bottom);
        } else if (window.pageYOffset < sectionTop) {
          // 섹션 시작점 이전 - 기본 상태
          containerRef.current.classList.remove(styles.fixed);
          containerRef.current.classList.remove(styles.bottom);
        } else {
          // 섹션 끝점 이후 - 하단에 고정
          containerRef.current.classList.remove(styles.fixed);
          containerRef.current.classList.add(styles.bottom);
        }
      }
      
      // 스크롤 위치가 섹션 내부일 때 활성 슬라이드 계산
      if (window.pageYOffset >= sectionTop && window.pageYOffset <= sectionEnd) {
        // 모바일 환경에서는 다른 계산 방식 적용
        const isMobile = window.innerWidth <= 1024;
        const sectionHeight = sectionRef.current.offsetHeight;
        
        // 스크롤 진행도에 따라 활성 슬라이드 계산
        const scrollProgress = (window.pageYOffset - sectionTop) / (sectionEnd - sectionTop);
        const slideIndex = Math.min(
          Math.floor(scrollProgress * slides.length),
          slides.length - 1
        );
        
        // 활성 슬라이드 인덱스가 변경된 경우에만 상태 업데이트
        if (slideIndex !== activeSlide) {
          // console.log(`Changing slide from ${activeSlide} to ${slideIndex}`); // 디버깅용
          
          // 변경 중 상태로 설정
          setIsChanging(true);
          
          // 기존 타임아웃 제거
          if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
          }
          
          // 이전 슬라이드 기록
          setPrevActiveSlide(activeSlide);
          
          // 새 활성 슬라이드 설정
          setActiveSlide(Math.max(0, slideIndex));
          
          // 일정 시간 후 변경 상태 초기화
          scrollTimeout.current = setTimeout(() => {
            setIsChanging(false);
          }, 800); // 트랜지션 시간과 일치시킴
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll); // 화면 크기 변경 시에도 상태 업데이트
    handleScroll(); // 초기 확인
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
      window.removeEventListener('resize', updateSectionHeight);
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, [activeSlide, slides.length]);

  return (
    <section ref={sectionRef} className={styles.section3}>
      <div ref={containerRef} className={styles.container}>
        {/* 왼쪽 패널 - 항상 아래에서 위로 올라오는 효과 */}
        <div ref={leftPanelRef} className={styles.leftPanel}>
          {/* 왼쪽 패널 배경 이미지 */}
          {slides.map((slide, index) => (
            <div 
              key={`left-bg-${slide.id}`}
              className={`
                ${styles.leftPanelBackground} 
                ${activeSlide === index ? styles.active : ''}
                ${prevActiveSlide === index ? styles.previous : ''}
              `}
              style={{
                backgroundImage: slide.leftPanel?.backgroundImage 
                  ? `url(${slide.leftPanel.backgroundImage})` 
                  : 'none'
              }}
            ></div>
          ))}
          
          <div className={styles.leftPanelOverlay}></div>
          
          <div className={styles.fixedContent}>
            {/* 현재 활성화된 슬라이드의 타이틀만 표시 */}
            {slides.map((slide, index) => (
              <h2 
                key={`title-${slide.id}`}
                className={`
                  ${styles.whatTitle} 
                  ${activeSlide === index ? styles.active : ''} 
                  ${prevActiveSlide === index ? styles.previous : ''}
                `}
                style={{
                  display: (activeSlide === index || prevActiveSlide === index) ? 'block' : 'none'
                }}
              >
                {slide.leftPanel.title}
              </h2>
            ))}
            
            {/* 현재 활성화된 슬라이드의 부제목만 표시 */}
            {slides.map((slide, index) => (
              slide.leftPanel.subtitle && (
                <p 
                  key={`subtitle-${slide.id}`}
                  className={`
                    ${styles.whatSubtitle} 
                    ${activeSlide === index ? styles.active : ''}
                    ${prevActiveSlide === index ? styles.previous : ''}
                  `}
                  style={{
                    display: (activeSlide === index || prevActiveSlide === index) ? 'block' : 'none'
                  }}
                >
                  {slide.leftPanel.subtitle}
                </p>
              )
            ))}
            
            {/* 슬라이드 인디케이터 제거됨 */}
          </div>
        </div>
        
        {/* 오른쪽 스크롤 패널 - 항상 위에서 아래로 내려오는 효과 */}
        <div ref={rightPanelRef} className={styles.rightPanel}>
          {slides.map((slide, index) => (
            <div 
              key={slide.id}
              className={`
                ${styles.slide} 
                ${activeSlide === index ? styles.active : ''}
                ${prevActiveSlide === index ? styles.previous : ''}
              `}
              style={{
                backgroundImage: slide.backgroundImage 
                  ? `url(${slide.backgroundImage})` 
                  : 'none',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundColor: !slide.backgroundImage ? '#f0f0f0' : 'transparent'
              }}
            >
              <div className={styles.slideContent}>
                {slide.title && (
                  <h3 className={`${styles.slideTitle} ${activeSlide === index ? styles.fadeIn : ''}`}>
                    {slide.title}
                  </h3>
                )}
                
                {slide.description && (
                  <p className={`${styles.slideDescription} ${activeSlide === index ? styles.fadeIn : ''}`}>
                    {slide.description}
                  </p>
                )}
                
                {slide.customText && slide.customText.map((text, i) => (
                  <p 
                    key={i} 
                    className={`${styles.customText} ${activeSlide === index ? styles.fadeIn : ''}`}
                    style={{ animationDelay: `${0.1 * (i + 1)}s` }}
                  >
                    {text}
                  </p>
                ))}
                
                {/* 배경 이미지만 있고 텍스트가 없는 경우 빈 컨테이너 표시 */}
                {slide.backgroundImage && !slide.customText && !slide.title && (
                  <div className={styles.imageOnlySlide}></div>
                )}
              </div>
            </div>
          ))}
        </div>
        
        {/* 첫 번째 슬라이드를 위한 스크롤 인디케이터 */}
        {/* {activeSlide === 0 && (
          <div className={styles.scrollDownIndicator}>
            <div className={styles.scrollText}>스크롤</div>
            <div className={styles.scrollArrow}></div>
          </div>
        )} */}
      </div>
    </section>
  );
};

export default Section3;