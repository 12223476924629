import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { projectsData } from '../../data/projectsData';
import styles from './Section4.module.scss';

const Section4: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const galleryRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  
  // 스크롤 진행률 상태 관리를 위한 ref
  const scrollProgressRef = useRef<number>(0);
  
  // 컨테이너의 상태 관리 (상단, 고정, 하단)
  const [containerState, setContainerState] = useState<'top' | 'fixed' | 'bottom'>('top');
  
  // 스크롤 진행률 상태 추가
  const [scrollProgress, setScrollProgress] = useState<number>(0);

  // 화면 크기에 따른 갤러리 조정을 위한 상태
  const [windowWidth, setWindowWidth] = useState<number>(0);

  // 윈도우 리사이즈 이벤트 처리
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // 초기 로드 시 윈도우 너비 설정
    setWindowWidth(window.innerWidth);
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 스크롤 위치에 따른 가로 이동 처리
  useEffect(() => {
    const handleScroll = () => {
      if (!sectionRef.current || !galleryRef.current || !titleRef.current) return;
      
      // 페이지 스크롤 위치 가져오기
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      
      // 섹션의 시작과 끝 위치 계산
      const sectionTop = sectionRef.current.offsetTop;
      const sectionHeight = sectionRef.current.offsetHeight;
      
      // 화면 크기에 따라 시작점 조정
      let startOffset = 0.1; // 기본값: 10%
      if (window.innerWidth <= 768) {
        startOffset = 0.05; // 태블릿: 5%
      }
      if (window.innerWidth <= 480) {
        startOffset = 0.03; // 모바일: 3%
      }
      
      // fixed 영역 시작/끝 지점 설정
      const fixedStartPoint = sectionTop + windowHeight * startOffset;
      const fixedEndPoint = sectionTop + sectionHeight * 0.7;  // 섹션 시작 후 70% 지점
      
      // 컨테이너 상태 업데이트
      if (scrollTop < fixedStartPoint) {
        // 아직 fixed 영역에 도달하지 않음 (상단 영역)
        setContainerState('top');
      } else if (scrollTop >= fixedStartPoint && scrollTop < fixedEndPoint) {
        // fixed 영역 내부
        setContainerState('fixed');
      } else {
        // fixed 영역을 지나침 (하단 영역)
        setContainerState('bottom');
      }
      
      // 갤러리 전체 가로 길이 (스크롤 가능한 최대 거리)
      const galleryWidth = galleryRef.current.scrollWidth;
      const visibleWidth = window.innerWidth;
      
      // 화면 크기에 따라 패딩 조정
      let sidePadding = 100; // 기본값
      if (window.innerWidth <= 768) {
        sidePadding = 60;
      }
      if (window.innerWidth <= 480) {
        sidePadding = 40;
      }
      
      // 모바일에서는 스크롤 거리를 약간 더 줄여서 마지막 항목이 잘 보이도록 설정
      let scrollEndAdjustment = 0;
      if (window.innerWidth <= 480) {
        scrollEndAdjustment = 120;
      } else if (window.innerWidth <= 768) {
        scrollEndAdjustment = 80;
      }
      
      const maxScrollDistance = galleryWidth - (visibleWidth - sidePadding) - scrollEndAdjustment;
      
      // 스크롤 진행률 계산 (0~1)
      let progress = 0;
      
      if (containerState === 'fixed') {
        // 고정 상태일 때만 스크롤 진행률 계산
        const totalScrollRange = fixedEndPoint - fixedStartPoint;
        const currentScrollPosition = Math.max(0, Math.min(
          scrollTop - fixedStartPoint,
          totalScrollRange
        ));
        
        progress = currentScrollPosition / totalScrollRange;
      } else if (containerState === 'bottom') {
        // 하단 영역에 도달하면 진행률을 1로 고정
        progress = 1;
      }
      
      // 현재 스크롤 진행률 저장
      scrollProgressRef.current = progress;
      setScrollProgress(progress);
      
      // 갤러리 이동 거리 계산 (0부터 maxScrollDistance까지)
      const translateX = -progress * maxScrollDistance;
      
      // 갤러리 이동 - transform 대신 translate3d로 성능 최적화
      if (galleryRef.current) {
        galleryRef.current.style.transform = `translate3d(${translateX}px, 0, 0)`;
      }
      
      // 타이틀 위치 조정 - 진행률에 따라 타이틀이 완전히 왼쪽으로 사라지도록
      if (titleRef.current) {
        // 모바일 화면에서는 더 빨리 사라지도록 설정
        let titleExitPoint = 0.3; // 기본값
        if (window.innerWidth <= 768) {
          titleExitPoint = 0.2; // 태블릿
        }
        if (window.innerWidth <= 480) {
          titleExitPoint = 0.15; // 모바일
        }
        
        const normalizedProgress = Math.min(progress / titleExitPoint, 1);
        
        // 화면 크기에 따라 시작 위치 조정
        let startPosition = 50;
        if (window.innerWidth <= 768) {
          startPosition = 30;
        }
        if (window.innerWidth <= 480) {
          startPosition = 20;
        }
        
        // z-index 조정 - 스크롤 진행에 따라 변경
        titleRef.current.style.zIndex = normalizedProgress >= 0.5 ? '0' : '3';
        
        const viewportWidth = window.innerWidth;
        const titleTranslateX = startPosition - (normalizedProgress * (viewportWidth + 300));
        
        titleRef.current.style.transform = `translate3d(${titleTranslateX}px, -50%, 0)`;
        titleRef.current.style.opacity = `${Math.max(0, 1 - normalizedProgress * 1.5)}`; // 투명도를 더 빨리 감소
      }
    };

    // 스크롤 이벤트 리스너 등록 (throttle 적용하여 성능 최적화)
    let ticking = false;
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollListener);
    // 초기 실행
    handleScroll();
    
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [containerState, windowWidth]); // windowWidth 의존성 추가

  return (
    <section ref={sectionRef} className={styles.section4}>
      <div 
        ref={containerRef} 
        className={`
          ${styles.container} 
          ${containerState === 'fixed' ? styles.fixed : ''} 
          ${containerState === 'bottom' ? styles.bottom : ''}
        `}
      >
        <div ref={titleRef} className={styles.title}>
          <h1>Works</h1>
        </div>
        
        <div className={styles.galleryContainer}>
          <div ref={galleryRef} className={styles.horizontalGallery}>
            {projectsData.map((project) => (
              <Link 
                key={project.id} 
                to={`/works/${project.slug}`}
                className={styles.projectCard}
              >
                <div className={styles.imageWrapper}>
                  <div className={styles.imagePlaceholder}>
                    {project.imageUrl ? (
                      <img src={project.imageUrl} alt={project.title} />
                    ) : (
                      <span>{project.id}</span>
                    )}
                  </div>
                </div>
                <div className={styles.projectInfo}>
                  <h3 className={styles.projectTitle}>{project.title}</h3>
                  <div className={styles.projectMeta}>
                    <span className={styles.projectCategory}>{project.category}</span>
                    <span className={styles.projectYear}>{project.year}</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      
      {/* 고정 컨테이너를 위한 공간 확보 (fixed 상태일 때 일반 흐름에서 빠지므로) */}
      <div className={styles.spacer} style={{ 
        height: containerState === 'fixed' ? '100vh' : '0' 
      }} />
    </section>
  );
};

export default Section4;