// src/data/projectsData.ts
import Img1 from '../assets/forest.jpg'
import Img2 from '../assets/leopard.jpg'
import Img3 from '../assets/palace.jpg'
import Img4 from '../assets/trees.jpg'
export interface Project {
    id: number;
    title: string;
    slug: string;
    category: string;
    year: string;
    excerpt: string;
    description: string;
    imageUrl?: string;
    detailImages?: string[];
    technologies?: string[];
    link?: string;
    githubLink?: string;
    featured: boolean;
  }
  
  export const projectsData: Project[] = [
    {
      id: 1,
      title: "1번제목",
      slug: "1",
      category: "1번카테고리",
      year: "1",
      excerpt: "1번 내용",
      description: "1번 더미데이터",
      imageUrl: Img4,
      technologies: ["React", "TypeScript", "Redux", "SCSS"],
      link: "/",
      githubLink: "/",
      featured: true
    },
    {
      id: 2,
      title: "2번제목",
      slug: "2",
      category: "2번카테고리",
      year: "2",
      excerpt: "2번 내용",
      description: "2번 더미데이터",
      imageUrl: Img2,
      technologies: ["React Native", "Firebase", "Redux", "Node.js"],
      link: "/",
      featured: true
    },
    {
      id: 3,
      title: "3번제목",
      slug: "3",
      category: "3번카테고리",
      year: "3",
      excerpt: "3번내용",
      description: "3번 더미데이터",
      imageUrl: Img3,
      technologies: ["HTML/CSS", "JavaScript", "SVG Animation", "Responsive Design"],
      link: "/",
      featured: true
    },
    {
      id: 4,
      title: "4번 제목",
      slug: "4",
      category: "4번카테고리",
      year: "4",
      excerpt: "설명4",
      description: "4번 더미데이터",
      imageUrl: Img1,
      technologies: ["WordPress", "Three.js", "CSS Grid", "Responsive Design"],
      link: "/",
      featured: false
    },
    // {
    //   id: 5,
    //   title: "5번제목",
    //   slug: "5번입니다",
    //   category: "5번카테고리",
    //   year: "5",
    //   excerpt: "설명5",
    //   description: "5번 더미데이터",
    //   // imageUrl: "/images/projects/less-platform.jpg",
    //   technologies: ["Next.js", "Shopify API", "Tailwind CSS", "Payment Gateway"],
    //   link: "/",
    //   featured: false
    // },
    // {
    //   id: 6,
    //   title: "6번제목",
    //   slug: "6번입니다",
    //   category: "2번카테고리",
    //   year: "6",
    //   excerpt: "6번 더미 데이터",
    //   description: "6번더미데이터입니다",
    //   // imageUrl: "/images/projects/detox-timer.jpg",
    //   technologies: ["Flutter", "Firebase", "Material Design", "Local Notifications"],
    //   link: "/",
    //   featured: false
    // }
  ];