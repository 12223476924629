import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import emailjs from '@emailjs/browser';
import styles from './ContactScreen.module.scss';

// 예시 배경 이미지를 사용하세요. 실제 이미지로 교체 필요
import contactBgImage from '../../assets/ex6.jpg'; // 실제 경로로 수정 필요

interface ContactProps {
  backgroundColor?: string;
  textColor?: string;
}

const Contact: React.FC<ContactProps> = ({ backgroundColor, textColor }) => {
  const { mode } = useSelector((state: RootState) => state.theme);
  const formRef = useRef<HTMLFormElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [titleVisible, setTitleVisible] = useState<boolean>(false);
  const titleRef = useRef<HTMLDivElement>(null);
  
  const [formData, setFormData] = useState({
    companyName: '',
    contactNumber: '',
    email: '',
    availableTime: '',
    budget: '',
    message: '',
    privacyAgreed: false
  });
  
  const [formErrors, setFormErrors] = useState<Partial<Record<keyof typeof formData, string>>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formStatus, setFormStatus] = useState<{
    type: 'success' | 'error' | null;
    message: string;
  }>({ type: null, message: '' });

  // IntersectionObserver를 사용하여 타이틀 요소가 화면에 보이는지 감지
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1 // 10% 이상 보이면 감지
    };

    // 타이틀 관찰자
    const titleObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTitleVisible(true);
          titleObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 타이틀 요소에 관찰자 등록
    if (titleRef.current) {
      titleObserver.observe(titleRef.current);
    }

    // 컴포넌트 언마운트 시 관찰자 해제
    return () => {
      if (titleRef.current) titleObserver.unobserve(titleRef.current);
    };
  }, []);

  // 사용자가 다시 입력을 시작하면 오류 메시지 초기화
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      setFormErrors({});
    }
  }, [formData]);

  // 전화번호 자동 서식 함수
  const formatPhoneNumber = (value: string): string => {
    // 숫자가 아닌 모든 문자 제거
    const digits = value.replace(/\D/g, '');
    
    if (digits.length <= 3) {
      return digits;
    } else if (digits.length <= 7) {
      return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    } else {
      return `${digits.slice(0, 3)}-${digits.slice(3, 7)}-${digits.slice(7, 11)}`;
    }
  };

  // 입력 필드 변경 핸들러
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    // 전화번호 서식 특별 처리
    if (name === 'contactNumber') {
      setFormData({
        ...formData,
        [name]: formatPhoneNumber(value)
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    });
  };

  // 폼 유효성 검사
  const validateForm = (): boolean => {
    const errors: Partial<Record<keyof typeof formData, string>> = {};
    
    // 회사명 유효성 검사
    if (!formData.companyName.trim()) {
      errors.companyName = '회사명은 필수 입력 항목입니다.';
    }

    // 이메일 유효성 검사
    if (!formData.email.trim()) {
      errors.email = '이메일은 필수 입력 항목입니다.';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        errors.email = '유효한 이메일 주소를 입력해주세요.';
      }
    }

    // 연락처 유효성 검사 (있을 경우만)
    if (formData.contactNumber.trim()) {
      const phoneRegex = /^\d{3}-\d{3,4}-\d{4}$|^\d{3}-\d{4}-\d{4}$/;
      if (!phoneRegex.test(formData.contactNumber)) {
        errors.contactNumber = '유효한 전화번호 형식이 아닙니다. (예: 010-1234-5678)';
      }
    }

    // 문의 내용 유효성 검사
    if (!formData.message.trim()) {
      errors.message = '문의 내용은 필수 입력 항목입니다.';
    }

    // 개인정보 처리방침 동의 검사
    if (!formData.privacyAgreed) {
      errors.privacyAgreed = '개인정보 처리방침에 동의해주세요.';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // 모달 토글
  const toggleModal = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    setIsModalOpen(!isModalOpen);
  };

  // 폼 제출 핸들러
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // 폼 유효성 검사
    if (!validateForm()) {
      setFormStatus({
        type: 'error',
        message: '입력 내용을 확인해주세요.'
      });
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // EmailJS를 사용하여 폼 데이터 전송
      const result = await emailjs.sendForm(
        'service_vv6v1l6',
        'template_xoc9p5c',
        formRef.current!,
        '8wmCZyJhO6K-Qeppx'
      );
      
      if (result.text === 'OK') {
        setFormStatus({
          type: 'success',
          message: '문의가 성공적으로 접수되었습니다. 곧 연락드리겠습니다.'
        });
        
        // 폼 초기화
        setFormData({
          companyName: '',
          contactNumber: '',
          email: '',
          availableTime: '',
          budget: '',
          message: '',
          privacyAgreed: false
        });
      }
    } catch (error) {
      console.error('이메일 전송 중 오류 발생:', error);
      setFormStatus({
        type: 'error',
        message: '문의 접수 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // 개인정보 처리방침 모달
  const PrivacyPolicyModal = () => (
    <div className={`${styles.modalOverlay} ${isModalOpen ? styles.active : ''}`}>
      <div className={`${styles.modalContent} ${mode === 'dark' ? styles.dark : ''}`}>
        <div className={styles.modalHeader}>
          <h2>개인정보처리방침</h2>
          <button className={styles.closeButton} onClick={toggleModal}>×</button>
        </div>
        <div className={styles.modalBody}>
          <p>비딩윈(플랫플랫)(이하 '회사'라 한다)는 개인정보 보호법 제30조에 따라 정보 주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립, 공개합니다.</p>
          
          <h3>제1조 (개인정보의 처리목적)</h3>
          <p>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
          <p>1. 홈페이지 회원 가입 및 관리<br />
          회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증, 회원자격 유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정 이용 방지, 만 14세 미만 아동의 개인정보처리 시 법정대리인의 동의 여부 확인, 각종 고지․통지, 고충 처리 등을 목적으로 개인정보를 처리합니다.</p>
          <p>2. 재화 또는 서비스 제공<br />
          물품 배송, 서비스 제공, 계약서 및 청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금 결제 및 정산, 채권추심 등을 목적으로 개인정보를 처리합니다.</p>
          <p>3. 고충 처리<br />
          민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지, 처리 결과 통보 등의 목적으로 개인정보를 처리합니다.</p>
          
          {/* 나머지 개인정보 처리방침 내용 */}
          {/* 생략 */}
          
          <h3>제13조(개인정보 처리방침 시행 및 변경)</h3>
          <p>이 개인정보 처리방침은 2021. 09. 15부터 적용됩니다.</p>
        </div>
        <div className={styles.modalFooter}>
          <button className={styles.closeButton} onClick={toggleModal}>닫기</button>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.contactSection} style={{ backgroundColor, color: textColor }}>
      {/* 타이틀 섹션 - Work1 스타일과 동일하게 적용 */}
      <div 
        ref={titleRef} 
        className={`${styles.titleSection} ${titleVisible ? styles.visible : ''}`}
      >
        <h1 className={styles.title}>문의하기</h1>
        <div className={styles.overlay}></div>
        <img 
          src={contactBgImage} 
          alt="미니마이즈 문의하기" 
          className={styles.backgroundImage} 
        />
      </div>
      
      <div className={styles.contactContainer}>
        <p className={styles.subtitle}>
          <span className={styles.impact}>문의주셔서</span> 감사합니다.<br />
          <span className={styles.impact}>전문적인 상담과 맞춤형 솔루션</span>으로<br /> 
          귀사의 디지털 전환을 도와드리겠습니다.
        </p>

        <div className={styles.formSection}>
          <form ref={formRef} onSubmit={handleSubmit} className={styles.contactForm}>
            <div className={styles.formGroup}>
              <label htmlFor="companyName">회사명 <span className={styles.required}>*</span></label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                required
                placeholder="회사명을 입력해주세요"
                className={formErrors.companyName ? styles.inputError : ''}
              />
              {formErrors.companyName && <div className={styles.errorMessage}>{formErrors.companyName}</div>}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="contactNumber">연락처</label>
              <input
                type="tel"
                id="contactNumber"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                placeholder="연락 가능한 전화번호를 입력해주세요"
                className={formErrors.contactNumber ? styles.inputError : ''}
              />
              {formErrors.contactNumber && <div className={styles.errorMessage}>{formErrors.contactNumber}</div>}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="email">이메일 <span className={styles.required}>*</span></label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="이메일 주소를 입력해주세요"
                className={formErrors.email ? styles.inputError : ''}
              />
              {formErrors.email && <div className={styles.errorMessage}>{formErrors.email}</div>}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="availableTime">상담 가능 시간</label>
              <input
                type="text"
                id="availableTime"
                name="availableTime"
                value={formData.availableTime}
                onChange={handleChange}
                placeholder="예: 평일 오전 10시~12시"
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="budget">예산 범위</label>
              <select
                id="budget"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
              >
                <option value="">예산 범위를 선택해주세요</option>
                <option value="500만원 미만">500만원 미만</option>
                <option value="500만원~1000만원">500만원~1000만원</option>
                <option value="1000만원~2000만원">1000만원~2000만원</option>
                <option value="2000만원~5000만원">2000만원~5000만원</option>
                <option value="5000만원 이상">5000만원 이상</option>
                <option value="협의 필요">협의 필요</option>
              </select>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="message">문의 내용 <span className={styles.required}>*</span></label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows={5}
                placeholder="문의하실 내용을 자세히 적어주세요"
                className={formErrors.message ? styles.inputError : ''}
              ></textarea>
              {formErrors.message && <div className={styles.errorMessage}>{formErrors.message}</div>}
            </div>

            <div className={styles.formGroup}>
              <div className={styles.privacyRow}>
                <label className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    name="privacyAgreed"
                    checked={formData.privacyAgreed}
                    onChange={handleCheckboxChange}
                  />
                  <span className={styles.checkmark}></span>
                  <span className={styles.checkboxLabel}>
                    <a href="#" className={styles.privacyLink} onClick={toggleModal}>개인정보 처리방침</a>에 동의합니다. <span className={styles.required}>*</span>
                  </span>
                </label>
              </div>
              {formErrors.privacyAgreed && <div className={styles.errorMessage}>{formErrors.privacyAgreed}</div>}
            </div>

            {formStatus.message && (
              <div className={`${styles.formStatus} ${formStatus.type === 'success' ? styles.success : styles.error}`}>
                {formStatus.message}
              </div>
            )}

            <div className={styles.submitContainer}>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? '전송 중...' : '문의 보내기'}
              </button>
            </div>
          </form>
        </div>

        <div className={styles.infoSection}>
          <div className={styles.infoCard}>
            <h3>직접 연락하기</h3>
            <p>
              <strong>이메일:</strong> biddingwin@naver.com<br />
              <strong>전화:</strong> 010-2649-3689<br />
              <strong>주소:</strong> 서울시 동작구 상도로 55길 8-3, B동 3층 303호
            </p>
          </div>
        </div>
      </div>
      
      {/* 개인정보 처리방침 모달 */}
      {isModalOpen && <PrivacyPolicyModal />}
    </div>
  );
};

export default Contact;