import React, { useEffect, useState, useRef } from 'react';
import styles from './Section2.module.scss';
// 이미지 불러오기
import ex1Image from '../../assets/ex1.jpg';
import ex2Image from '../../assets/ex2.jpg';
import ex3Image from '../../assets/ex3.jpg';

interface ContentItem {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
}

const Section2: React.FC = () => {
  const [visibleItems, setVisibleItems] = useState<number[]>([]);
  const [titleVisible, setTitleVisible] = useState<boolean>(false);
  const sectionRef = useRef<HTMLElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  // 샘플 콘텐츠 데이터
  const contentItems: ContentItem[] = [
    {
      id: 1,
      title: "업무 자동화",
      description: "반복 업무를 자동화해 시간을 절약합니다.\n남는 시간은 더 창의적인 일에 쓰세요.",
      imageUrl: ex1Image 
    },
    {
      id: 2,
      title: "빠른 실행력",
      description: "상담부터 결과 제공까지 기다림이 없습니다.\n필요한 순간, 가장 빠르게 지원합니다.",
      imageUrl: ex2Image  
    },
    {
      id: 3,
      title: "맞춤형 솔루션",
      description: "다누구에게나 통하는 답은 없습니다.\n당신의 상황에 최적화된 해법을 만듭니다.",
      imageUrl: ex3Image
    }
  ];

  // 화면 크기 감지
  const [windowWidth, setWindowWidth] = useState<number>(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // IntersectionObserver를 사용하여 요소가 화면에 보이는지 감지
  useEffect(() => {
    // 타이틀 관찰자
    const titleObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTitleVisible(true);
          titleObserver.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.1
    });

    if (titleRef.current) {
      titleObserver.observe(titleRef.current);
    }

    // 콘텐츠 항목 관찰자
    const itemOptions = {
      root: null,
      rootMargin: '0px',
      threshold: windowWidth <= 768 ? 0.05 : 0.1 // 모바일에서는 더 일찍 감지
    };

    const itemObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const id = Number(entry.target.getAttribute('data-id'));
          setVisibleItems(prev => {
            if (!prev.includes(id)) {
              return [...prev, id];
            }
            return prev;
          });
        }
      });
    }, itemOptions);

    // 각 항목에 observer 등록
    itemRefs.current.forEach(ref => {
      if (ref) itemObserver.observe(ref);
    });

    return () => {
      if (titleRef.current) titleObserver.unobserve(titleRef.current);
      itemRefs.current.forEach(ref => {
        if (ref) itemObserver.unobserve(ref);
      });
    };
  }, [windowWidth]);

  // 화면 크기에 따른 텍스트와 이미지 순서 결정
  const getContentRowLayout = (isEven: boolean, isVisible: boolean, item: ContentItem, index: number) => {
    // 모바일 화면에서는 항상 이미지가 먼저 나오도록
    if (windowWidth <= 1024) {
      return (
        <>
          <div className={`${styles.imageContainer} ${isVisible ? styles.animateImage : ''}`}>
            <div className={styles.imagePlaceholder}>
              {item.imageUrl ? (
                <img src={item.imageUrl} alt={item.title} loading="lazy" />
              ) : (
                <span>이미지 {item.id}</span>
              )}
            </div>
          </div>
          
          <div className={`${styles.textContent} ${isVisible ? styles.animateText : ''}`}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        </>
      );
    }
    
    // 데스크톱 화면에서는 지그재그 레이아웃
    if (isEven) {
      return (
        <>
          <div className={`${styles.textContent} ${isVisible ? styles.animateText : ''}`}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
          
          <div className={`${styles.imageContainer} ${isVisible ? styles.animateImage : ''}`}>
            <div className={styles.imagePlaceholder}>
              {item.imageUrl ? (
                <img src={item.imageUrl} alt={item.title} loading="lazy" />
              ) : (
                <span>이미지 {item.id}</span>
              )}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={`${styles.imageContainer} ${isVisible ? styles.animateImage : ''}`}>
            <div className={styles.imagePlaceholder}>
              {item.imageUrl ? (
                <img src={item.imageUrl} alt={item.title} loading="lazy" />
              ) : (
                <span>이미지 {item.id}</span>
              )}
            </div>
          </div>
          
          <div className={`${styles.textContent} ${isVisible ? styles.animateText : ''}`}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        </>
      );
    }
  };

  return (
    <section ref={sectionRef} className={styles.section2}>
      <div className={styles.container}>
        <div ref={titleRef} className={styles.sectionTitle}>
          
        </div>
        
        {contentItems.map((item, index) => {
          const isEven = index % 2 === 1;
          const isVisible = visibleItems.includes(item.id);
          
          return (
            <div 
              key={item.id}
              ref={(el: HTMLDivElement | null): void => {
                itemRefs.current[index] = el;
              }}
              data-id={item.id}
              className={`${styles.contentRow} ${isEven && windowWidth > 1024 ? styles.reverse : ''} ${isVisible ? styles.visible : ''}`}
            >
              {getContentRowLayout(isEven, isVisible, item, index)}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Section2;