import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Contact.module.scss';

// 배경 이미지 임포트 (실제 프로젝트에서는 경로 수정 필요)
import backgroundImage from '../../assets/ex6.jpg';

interface ContactProps {
  // 필요한 경우 props 추가
  backgroundColor?: string;
  textColor?: string;
}

const Contact: React.FC<ContactProps> = ({ 
  backgroundColor = '#2a2a2a', 
  textColor = '#ffffff'
}) => {
  return (
    <div className={styles.contactSection}>
      <div 
        className={styles.contactContainer}
        style={{ backgroundColor }}
      >
        <div className={styles.contactImageBox}>
          {/* 배경 이미지 */}
          <div className={styles.backgroundImageContainer}>
            <img src={backgroundImage} alt="문의하기 배경" className={styles.backgroundImage} />
            <div className={styles.imageOverlay}></div>
          </div>
          
          {/* 텍스트 및 버튼 컨텐츠 */}
          <div className={styles.contactContent}>
          <p className={styles.contactText}>
              Contact
            </p>
            <h2 className={styles.contactTitle} >
            미니마이즈로 최소화 해 보세요
            </h2>
            <Link to="/contact" className={styles.contactButton}>
              문의하기
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;