import React, { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import Work1 from '../../components/Works/work1';
import Work2 from '../../components/Works/work2';
import Work3 from '../../components/Works/work3';
import Work4 from '../../components/Works/work4';
import Footer from '../../components/Footer/Footer';
import styles from './WorksScreen2.module.scss';

const WorksScreen2: React.FC = () => {
  // URL에서 workId 파라미터 가져오기
  const { workId } = useParams<{ workId: string }>();
  
  // 컴포넌트가 마운트될 때 스크롤 위치를 맨 위로 이동
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  }, [workId]); // workId가 변경될 때마다 실행되도록 의존성 배열에 추가

  // 작품 ID에 따라 다른 컴포넌트 렌더링
  const renderWorkComponent = () => {
    switch (workId) {
      case '1':
        return <Work1 />;
      case '2':
        return <Work2 />;
      case '3':
        return <Work3 />;
      case '4':
        return <Work4 />;
      default:
        // 잘못된 ID의 경우 기본 페이지나 404 페이지로 리다이렉트
        return <Navigate to="/" replace />;
    }
  };

  return (
    <div className={styles.worksScreenContainer}>
      <div className="works-screen">
        {renderWorkComponent()}
      </div>
      <Footer></Footer>
    </div>
  );
};

export default WorksScreen2;