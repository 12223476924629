import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { projectsData, Project } from '../../data/projectsData';
import styles from './WorksScreen.module.scss';

const WorksScreen: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>(projectsData);
  const [filter, setFilter] = useState<string>('all');
  const [isLoaded, setIsLoaded] = useState(false);

  // 프로젝트 카테고리 추출 (중복 제거)
  const categories = ['all', ...Array.from(new Set(projectsData.map(project => project.category)))];

  // 카테고리 필터링
  const handleFilterChange = (category: string) => {
    setFilter(category);
    if (category === 'all') {
      setProjects(projectsData);
    } else {
      setProjects(projectsData.filter(project => project.category === category));
    }
  };

  // 페이지 로드 애니메이션
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div className={styles.worksScreen}>
      <div className={styles.container}>
        <div className={`${styles.pageHeader} ${isLoaded ? styles.loaded : ''}`}>
          <h1 className={styles.pageTitle}>Works</h1>
          <p className={styles.pageDescription}>미니멀리즘 원칙에 따라 설계된 다양한 프로젝트들을 소개합니다.</p>
        </div>

        <div className={`${styles.filterContainer} ${isLoaded ? styles.loaded : ''}`}>
          {categories.map((category, index) => (
            <button
              key={index}
              className={`${styles.filterButton} ${filter === category ? styles.active : ''}`}
              onClick={() => handleFilterChange(category)}
            >
              {category === 'all' ? '전체' : category}
            </button>
          ))}
        </div>

        <div className={styles.projectsGrid}>
          {projects.map((project, index) => (
            <div
              key={project.id}
              className={`${styles.projectCard} ${isLoaded ? styles.loaded : ''}`}
              style={{ animationDelay: `${0.1 * index}s` }}
            >
              <Link to={`/works/${project.slug}`} className={styles.projectLink}>
                <div className={styles.projectImage}>
                  <div className={styles.imageContainer}>
                    {project.imageUrl ? (
                      <img src={project.imageUrl} alt={project.title} />
                    ) : (
                      <div className={styles.imagePlaceholder}>
                        <span>{project.id}</span>
                      </div>
                    )}
                  </div>
                  <div className={styles.projectOverlay}>
                    <span className={styles.viewText}>View Project</span>
                  </div>
                </div>
                <div className={styles.projectInfo}>
                  <h2 className={styles.projectTitle}>{project.title}</h2>
                  <div className={styles.projectMeta}>
                    <span className={styles.projectCategory}>{project.category}</span>
                    <span className={styles.projectYear}>{project.year}</span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorksScreen;