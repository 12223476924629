import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './work2.module.scss';
import galleryStyles from '../imageGalleryPreview/imageGalleryPreview.module.scss';
import WorkPage from '../WorkPage/WorkPage';

// Import images
// Note: You'll need to replace these with your actual images
import heroImage from '../../assets/ex8.jpg';
import image1 from '../../assets/Work2-1.png';
import image2 from '../../assets/Work2-2.png';

const Work2: React.FC = () => {
  // Sample data for Work2
  const workData = {
    title: "고객관리 CRM 프로그램",
    subtitle: "<span class=\"" + styles.impact + "\">정보관리부터 정산까지</span> 한번에!<br /><span class=\"" + styles.impact + "\">효과적인 업무관리</span><br />미니마이즈의 CRM솔루션으로 시작하세요",
    heroImage: heroImage,
    
    workImages: [
      {
        id: 1,
        src: image1,
        alt: "대시보드 메인 화면",
        text: "복잡하고, 방대한 스프레드 시트관리 CRM을 통해 쉽고 간단하게 해결하세요."
      },  
      {
        id: 2,
        src: image2,
        alt: "대시보드 메인 화면",
        text: "상태별로 나눠지고 동기화 되지 않아 발생되는 실수와 불필요한 에너지 소모를 미니마이즈로 해결하세요."
      }
    ],
    
    circleFeatures: [
      {
        id: 1,
        src: image1,
        alt: "정보관리",
        title: "정보관리",
        description: "사용자 관한별로 체계화 된 정보관리로 업무의 효율성을 높혀보세요."
      },
      {
        id: 2,
        src: image2,
        alt: "업무관리",
        title: "업무관리",
        description: "업무 분배부터, 소재 현황, 일정 관리, 보고서 관리 등 여러가지 업무관리를 쉽고 빠르게 도와드립니다."
      },
      {
        id: 3,
        src: image1,
        alt: "정산관리",
        title: "정산관리",
        description: "복잡하고 어려웠던 정산관리를 CRM에서 한번에 해결해보세요."
      }
    ],
    
    projectDescription: [
      "미니마이즈 CRM은 방대하고 복잡하게 나누어져 있던 구글 스프레드시트의 단점을 극복하고 장점을 살려 술루션을 구현하였습니다.",
      "직관적고 사용자의 경험에 맞는 UI로 귀사만의 솔루션을 구현해드립니다. 정보관리부터 업무 정산까지 CRM으로 한번에 해결해보세요."
    ]
  };

  return (
    <WorkPage
      title={workData.title}
      subtitle={workData.subtitle}
      heroImage={workData.heroImage}
      workImages={workData.workImages}
      circleFeatures={workData.circleFeatures}
      projectDescription={workData.projectDescription}
      styles={styles}
      galleryStyles={galleryStyles}
    />
  );
};

export default Work2;