import Img1 from '../assets/forest.jpg'
import Img2 from '../assets/leopard.jpg'
import Img3 from '../assets/palace.jpg'
import Img4 from '../assets/trees.jpg'

export interface SlideContent {
  id: number;
  title?: string;
  description?: string;
  backgroundImage?: string;
  customText?: string[];
  leftPanel: {
    title: string;
    subtitle?: string;
    backgroundImage?: string; // 왼쪽 패널에 배경 이미지 추가
  };
}

export const whatWeDoData: SlideContent[] = [
  {
    id: 1,
    title: 'We Do',
    backgroundImage: Img4,
    description: '우리는 복잡한 것을 단순하게 만드는 일을 합니다. 미니멀리즘은 단순히 디자인 스타일이 아닌 철학입니다. 우리는 사용자에게 꼭 필요한 것만 남기고 불필요한 요소를 제거함으로써 더 명확하고 직관적인 경험을 제공합니다. 복잡함 속에서 단순함을 찾아내는 과정이 우리가 하는 일의 핵심입니다.',
    leftPanel: {
      title: 'What',
      // subtitle: '우리는 무엇을 하는가',
      backgroundImage: Img3
    }
  },
  {
    id: 2,
    backgroundImage: Img1,
    customText: ['더미데이터,더미데이터'],
    leftPanel: {
      title: 'Why',
      // subtitle: '우리가 하는 이유',
      backgroundImage: Img2
    }
  },
  {
    id: 3,
    backgroundImage: Img2,
    customText: ['더미데이터,더미데이터'],
    leftPanel: {
      title: 'How',
      // subtitle: '우리의 방법론',
      backgroundImage: Img1
    }
  },
  {
    id: 4,
    backgroundImage: Img3,
    customText: ['더미데이터,더미데이터'],
    leftPanel: {
      title: 'Who',
      // subtitle: '우리는 누구인가',
      backgroundImage: Img4
    }
  }
];