import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Contact from '../../components/Contact/Contact';

// 인터페이스 정의
interface WorkImage {
  id: number;
  src: string;
  alt: string;
  text: string;
}

interface CircleFeature {
  id: number;
  src: string;
  alt: string;
  title: string;
  description: string;
}

// 이미지 갤러리 인터페이스
interface ImageItem {
  id: number;
  src: string;
  alt: string;
  title: string;
  description: string;
}

// WorkPage 컴포넌트 props 인터페이스
interface WorkPageProps {
  title: string;
  subtitle: string;
  heroImage: string;
  workImages: WorkImage[];
  circleFeatures: CircleFeature[];
  galleryImages?: ImageItem[]; // 옵셔널로 설정
  projectDescription: string[];
  styles: any;
  galleryStyles: any;
}

const WorkPage: React.FC<WorkPageProps> = ({
  title,
  subtitle,
  heroImage,
  workImages,
  circleFeatures,
  galleryImages,
  projectDescription,
  styles,
  galleryStyles
}) => {
  const { mode } = useSelector((state: RootState) => state.theme);
  const [imagesVisible, setImagesVisible] = useState<boolean[]>([]);
  const [titleVisible, setTitleVisible] = useState<boolean>(false);
  const [descriptionVisible, setDescriptionVisible] = useState<boolean>(false);
  const [featuresVisible, setFeaturesVisible] = useState<boolean>(false);
  const [galleryVisible, setGalleryVisible] = useState<boolean>(false);
  const imagesRef = useRef<(HTMLDivElement | null)[]>([]);
  const titleRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const galleryRef = useRef<HTMLDivElement>(null);
  
  // 이미지 갤러리 관련 상태
  const [selectedImage, setSelectedImage] = useState<ImageItem | null>(null);

  // 컴포넌트 마운트 시 imagesVisible 상태 초기화 및 첫 번째 갤러리 이미지 선택
  useEffect(() => {
    // workImages 길이에 맞게 imagesVisible 배열 초기화
    setImagesVisible(new Array(workImages.length).fill(false));
    
    // galleryImages가 있으면 첫 번째 이미지 선택
    if (galleryImages && galleryImages.length > 0) {
      setSelectedImage(galleryImages[0]);
    }
  }, [workImages.length, galleryImages]);

  // IntersectionObserver를 사용하여 요소가 화면에 보이는지 감지
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1 // 10% 이상 보이면 감지
    };

    // 타이틀 관찰자
    const titleObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTitleVisible(true);
          titleObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 원형 이미지 섹션 관찰자
    const featuresObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setFeaturesVisible(true);
          featuresObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 이미지 관찰자
    const imageObservers = imagesRef.current.map((ref, index) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setImagesVisible(prev => {
              const newState = [...prev];
              newState[index] = true;
              return newState;
            });
            observer.unobserve(entry.target);
          }
        });
      }, { ...observerOptions, threshold: 0.2 });
      return observer;
    });

    // 갤러리 관찰자
    const galleryObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setGalleryVisible(true);
          galleryObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 설명 관찰자
    const descriptionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setDescriptionVisible(true);
          descriptionObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 각 요소에 관찰자 등록
    if (titleRef.current) {
      titleObserver.observe(titleRef.current);
    }

    if (featuresRef.current) {
      featuresObserver.observe(featuresRef.current);
    }

    imagesRef.current.forEach((ref, index) => {
      if (ref) imageObservers[index].observe(ref);
    });

    if (galleryRef.current && galleryImages && galleryImages.length > 0) {
      galleryObserver.observe(galleryRef.current);
    }

    if (descriptionRef.current) {
      descriptionObserver.observe(descriptionRef.current);
    }

    // 컴포넌트 언마운트 시 관찰자 해제
    return () => {
      if (titleRef.current) titleObserver.unobserve(titleRef.current);
      
      if (featuresRef.current) featuresObserver.unobserve(featuresRef.current);
      
      imagesRef.current.forEach((ref, index) => {
        if (ref) imageObservers[index].unobserve(ref);
      });
      
      if (galleryRef.current && galleryImages && galleryImages.length > 0) {
        galleryObserver.unobserve(galleryRef.current);
      }
      
      if (descriptionRef.current) descriptionObserver.unobserve(descriptionRef.current);
    };
  }, [galleryImages]);

  // 이미지 선택 핸들러
  const handleImageSelect = (image: ImageItem) => {
    setSelectedImage(image);
  };

  return (
    <div className={`${styles.work1Container} ${mode === 'dark' ? styles.dark : ''}`}>
      {/* 타이틀 섹션 */}
      <div 
        ref={titleRef} 
        className={`${styles.titleSection} ${titleVisible ? styles.visible : ''}`}
      >
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.overlay}></div>
        <img 
          src={heroImage} 
          alt={title} 
          className={styles.backgroundImage} 
        />
      </div>
      <p className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }}></p>
      
      {/* 새로운 원형 이미지 섹션 */}
      <div
        ref={featuresRef}
        className={`${styles.featuresSection} ${featuresVisible ? styles.visible : ''}`}
      >
        <div className={styles.featuresContainer}>
          {circleFeatures.map((feature, index) => (
            <div 
              key={feature.id} 
              className={styles.featureItem}
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              <div className={styles.circleImageContainer}>
                <img src={feature.src} alt={feature.alt} className={styles.circleImage} />
              </div>
              <div className={styles.featureContent}>
                <h3 className={styles.featureTitle}>{feature.title}</h3>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* 이미지 갤러리 - 갤러리 이미지가 있는 경우에만 표시 */}
      {galleryImages && galleryImages.length > 0 && (
        <div 
          ref={galleryRef}
          className={`${styles.galleryPreviewSection} ${galleryVisible ? styles.visible : ''}`}
        >
          <div className={galleryStyles.galleryContainer}>
            <div className={galleryStyles.galleryWrapper}>
              {/* 왼쪽 섹션 - 큰 이미지 */}
              <div className={galleryStyles.leftSection}>
                <div className={galleryStyles.mainImageWrapper}>
                  {selectedImage && (
                    <img 
                      src={selectedImage.src} 
                      alt={selectedImage.alt}
                      className={galleryStyles.mainImage}
                    />
                  )}
                </div>
              </div>
              
              {/* 오른쪽 섹션 - 선택된 이미지의 번호, 제목과 설명 */}
              <div className={galleryStyles.rightSection}>
                {selectedImage && (
                  <>
                    <span className={galleryStyles.imageNumber}>0{selectedImage.id}</span>
                    <h2 className={galleryStyles.imageTitle}>{selectedImage.title}</h2>
                    <p className={galleryStyles.imageDescription}>{selectedImage.description}</p>
                  </>
                )}
                
                {/* 가로로 정렬된 썸네일 컨테이너 */}
                <div className={galleryStyles.thumbnailContainer}>
                  {galleryImages.map((image) => (
                    <div 
                      key={image.id}
                      className={`${galleryStyles.thumbnailItem} ${selectedImage && selectedImage.id === image.id ? galleryStyles.active : ''}`}
                      onClick={() => handleImageSelect(image)}
                    >
                      <div className={galleryStyles.thumbnailImageWrapper}>
                        <img 
                          src={image.src} 
                          alt={image.alt}
                          className={galleryStyles.thumbnailImage}
                        />
                      </div>
                      <div className={galleryStyles.thumbnailContent}>
                        <span className={galleryStyles.thumbnailNumber}>0{image.id}</span>
                        <h3 className={galleryStyles.thumbnailTitle}>{image.title}</h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* 이미지 섹션 */}
      <div className={styles.imageGallery}>
        {workImages.map((image, index) => (
          <div 
            key={image.id}
            ref={(el) => { imagesRef.current[index] = el }}
            className={`${styles.imageContainer} ${imagesVisible[index] ? styles.visible : ''}`}
            style={{ animationDelay: `${index * 0.2}s` }}
          >
            <div className={styles.imageWrapper}>
              <img src={image.src} alt={image.alt} className={styles.workImage} />
            </div>
            <p className={styles.imageText}>{image.text}</p>
          </div>
        ))}
      </div>
      
      {/* 설명 섹션 */}
      <div 
        ref={descriptionRef}
        className={`${styles.descriptionSection} ${descriptionVisible ? styles.visible : ''}`}
      >
        <div className={styles.descriptionWrapper}>
          <h2 className={styles.descriptionTitle}>프로젝트 상세 설명</h2>
          {projectDescription.map((paragraph, index) => (
            <p key={index} className={styles.descriptionText}>{paragraph}</p>
          ))}
        </div>
      </div>
      
      {/* 문의하기 섹션 */}
      <Contact 
        backgroundColor={mode === 'dark' ? '#2a2a2a' : '#f9f9f9'}
        textColor={mode === 'dark' ? '#f0f0f0' : '#333'}
      />
    </div>
  );
};

export default WorkPage;