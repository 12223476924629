import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Section1 from '../../components/Main/Section1';
import Section2 from '../../components/Main/Section2';
import Section3 from '../../components/Main/Section3';
import Section4 from '../../components/Main/Section4';
import Section5 from '../../components/Main/Section5';
import Footer from '../../components/Footer/Footer';
import styles from './MainScreen.module.scss';

const MainScreen: React.FC = () => {
  // 테마 상태 가져오기
  const { mode } = useSelector((state: RootState) => state.theme);

  return (
    <div className={`${styles.mainScreen} ${mode === 'light' ? styles.light : styles.dark}`}>
      <Section1 />
      <Section5 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer/>
    </div>
  );
};

export default MainScreen;