import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './work3.module.scss';
import galleryStyles from '../imageGalleryPreview/imageGalleryPreview.module.scss';
import WorkPage from '../WorkPage/WorkPage';

// Import images
// Note: You'll need to replace these with your actual images
import heroImage from '../../assets/ex8.jpg';
import image1 from '../../assets/work1-1.png';
import image2 from '../../assets/work1-2.png';

const Work3: React.FC = () => {
  // Sample data for Work3
  const workData = {
    title: "스마트 재고 관리 시스템",
    subtitle: "<span class=\"" + styles.impact + "\">자동화된 재고 추적</span>으로 비즈니스 효율성 극대화<br /><span class=\"" + styles.impact + "\">실시간 모니터링과 예측 알고리즘</span><br />미니마이즈의 솔루션으로 재고 문제를 해결하세요",
    heroImage: heroImage,
    
    workImages: [
      {
        id: 1,
        src: image1,
        alt: "재고 관리 대시보드",
        text: "직관적인 대시보드를 통해 재고 현황과 주요 지표를 한눈에 파악할 수 있습니다."
      }
    ],
    
    circleFeatures: [
      {
        id: 1,
        src: image1,
        alt: "바코드/QR 스캔",
        title: "바코드/QR 스캔",
        description: "모바일 앱과 연동된 스캐닝 시스템으로 재고 입출고를 간편하게 관리합니다."
      },
      {
        id: 2,
        src: image2,
        alt: "자동 발주 시스템",
        title: "자동 발주 시스템",
        description: "설정된 임계값에 따라 자동으로 발주를 진행하여 재고 부족 문제를 예방합니다."
      },
      {
        id: 3,
        src: image1,
        alt: "위치 기반 관리",
        title: "위치 기반 관리",
        description: "창고 내 상품의 정확한 위치를 추적하여 피킹 시간을 단축하고 효율성을 높입니다."
      }
    ],
    
    galleryImages: [
      {
        id: 1,
        src: image1,
        alt: "재고 모니터링",
        title: "재고 모니터링",
        description: "실시간으로 재고 수준을 모니터링하고 중요한 변화를 즉시 알림으로 받습니다."
      },
      {
        id: 2,
        src: image2,
        alt: "수요 예측",
        title: "수요 예측",
        description: "AI 기반 알고리즘으로 향후 재고 수요를 예측하여 최적의 재고 수준을 유지합니다."
      },
      {
        id: 3,
        src: image1,
        alt: "공급망 통합",
        title: "공급망 통합",
        description: "공급업체와의 통합으로 발주부터 입고까지 전 과정을 자동화합니다."
      },
      {
        id: 4,
        src: image2,
        alt: "분석 리포트",
        title: "분석 리포트",
        description: "상세한 분석 리포트로 재고 회전율, 데드 스톡 등 핵심 지표를 확인할 수 있습니다."
      }
    ],
    
    projectDescription: [
      "스마트 재고 관리 시스템은 기업의 재고 관련 모든 프로세스를 디지털화하고 자동화하는 종합 솔루션입니다. 바코드/QR 스캔 기능과 클라우드 기반 데이터베이스를 통해 재고 현황을 실시간으로 파악할 수 있습니다.",
      "미니마이즈의 재고 관리 시스템은 사용자 친화적인 인터페이스와 강력한 분석 기능을 결합하여 재고 최적화, 비용 절감, 그리고 고객 만족도 향상에 기여합니다. 자동화된 시스템으로 인적 오류를 최소화하고 업무 효율성을 높여보세요."
    ]
  };

  return (
    <WorkPage
      title={workData.title}
      subtitle={workData.subtitle}
      heroImage={workData.heroImage}
      workImages={workData.workImages}
      circleFeatures={workData.circleFeatures}
      galleryImages={workData.galleryImages}
      projectDescription={workData.projectDescription}
      styles={styles}
      galleryStyles={galleryStyles}
    />
  );
};

export default Work3;