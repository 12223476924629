import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './work4.module.scss';
import galleryStyles from '../imageGalleryPreview/imageGalleryPreview.module.scss';
import WorkPage from '../WorkPage/WorkPage';

// Import images
// Note: You'll need to replace these with your actual images
import heroImage from '../../assets/ex8.jpg';
import image1 from '../../assets/work1-1.png';
import image2 from '../../assets/work1-2.png';

const Work4: React.FC = () => {
  // Sample data for Work4
  const workData = {
    title: "고객 관계 관리(CRM) 솔루션",
    subtitle: "<span class=\"" + styles.impact + "\">고객 정보 통합 관리</span>로 영업 효율 극대화<br /><span class=\"" + styles.impact + "\">자동화된 마케팅과 고객 지원</span><br />미니마이즈의 CRM으로 고객 중심 비즈니스를 구현하세요",
    heroImage: heroImage,
    
    workImages: [
      {
        id: 1,
        src: image1,
        alt: "CRM 대시보드",
        text: "사용자 친화적인 인터페이스로 고객 데이터, 영업 파이프라인, 마케팅 캠페인을 한눈에 관리할 수 있습니다."
      }
    ],
    
    circleFeatures: [
      {
        id: 1,
        src: image1,
        alt: "고객 데이터 통합",
        title: "고객 데이터 통합",
        description: "다양한 채널에서 수집된 고객 데이터를 단일 플랫폼에서 통합하여 360° 고객 뷰를 제공합니다."
      },
      {
        id: 2,
        src: image2,
        alt: "영업 자동화",
        title: "영업 자동화",
        description: "리드 생성부터 계약 체결까지 영업 프로세스를 자동화하여 영업팀의 효율성을 높입니다."
      },
      {
        id: 3,
        src: image1,
        alt: "고객 세그먼테이션",
        title: "고객 세그먼테이션",
        description: "고객 데이터를 분석하여 맞춤형 마케팅과 서비스를 제공할 수 있는 세분화된 그룹을 생성합니다."
      }
    ],
    
    galleryImages: [
      {
        id: 1,
        src: image1,
        alt: "고객 프로필",
        title: "고객 프로필",
        description: "상세한 고객 프로필로 구매 이력, 선호도, 상호작용 기록 등을 한눈에 확인할 수 있습니다."
      },
      {
        id: 2,
        src: image2,
        alt: "영업 파이프라인",
        title: "영업 파이프라인",
        description: "직관적인 드래그 앤 드롭 인터페이스로 영업 기회를 쉽게 관리하고 추적합니다."
      },
      {
        id: 3,
        src: image1,
        alt: "마케팅 자동화",
        title: "마케팅 자동화",
        description: "트리거 기반 이메일, SMS, 푸시 알림으로 타겟 마케팅 캠페인을 자동화합니다."
      },
      {
        id: 4,
        src: image2,
        alt: "성과 분석",
        title: "성과 분석",
        description: "다양한 KPI와 지표를 통해 영업 및 마케팅 활동의 ROI를 측정하고 최적화합니다."
      }
    ],
    
    projectDescription: [
      "고객 관계 관리(CRM) 솔루션은 기업이 고객과의 관계를 체계적으로 관리하고 개선할 수 있는 종합 플랫폼입니다. 영업, 마케팅, 고객 서비스 등 모든 고객 접점을 통합하여 일관된 고객 경험을 제공합니다.",
      "미니마이즈의 CRM 솔루션은 클라우드 기반으로 언제 어디서나 접근 가능하며, AI 기반 인사이트로 데이터 기반 의사결정을 지원합니다. 고객 데이터의 효과적인 활용으로 고객 만족도를 높이고 비즈니스 성장을 가속화하세요."
    ]
  };

  return (
    <WorkPage
      title={workData.title}
      subtitle={workData.subtitle}
      heroImage={workData.heroImage}
      workImages={workData.workImages}
      circleFeatures={workData.circleFeatures}
      galleryImages={workData.galleryImages}
      projectDescription={workData.projectDescription}
      styles={styles}
      galleryStyles={galleryStyles}
    />
  );
};

export default Work4;